.sitemap-menu {
    &__item {
        list-style: none;
    }

    &__link {
        display: flex;
        align-items: center;
        color: $color-text;
        text-decoration: none;
        margin-bottom: 5px;

        &_level {

            &_0 {
                font-weight: bold;
                font-size: 16px;
            }

            &_1 {
                &:before {
                    content: '';
                    display: flex;
                    align-items: center;
                    width: 10px;
                    height: 2px;
                    background-color: $color-cyan;
                    margin-right: 5px;
                }
            }

            &_2 {
                font-style: italic;
            }
        }
    }
}
