.burger {

    top: 0;
    left: 0;
    margin: 0;
    padding: 50px 20px 43px 50px;
    box-sizing: border-box;
    position: fixed;
    background-color: #00a1ce;
    z-index: 11;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transform: translateX(-100%);
    transition: transform .6s ease;

    @media screen and (max-width: 976px) {

        transform: translateX(0);
        transform: translateY(-100%);
        width: 100%;
        padding: 50px 50px 43px 50px;

    }

    &_active{

        transform: translateX(0);
        transition: transform .6s ease;

        @media screen and (max-width: 976px) {

            transform: translateY(0);

        }

    }


    &__wrapper {

        display: flex;
        align-items: center;

    }


    &__feedback {

        max-width: 230px;
    }

    &__text {

        font-size: 15px;
        color: #FFF;
        margin-bottom: 10px;

    }
        &__text a{
            color:#FFF;
        }

    &__button {

        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 10px;
        border: 1px solid #FFF;
        border-radius: 6px;
        color: #FFF;
        font-size: 15px;
        text-decoration: underline;
        cursor: pointer;
    }

    &__site-map {

        color: #FFF;
        font-size: 15px;
        text-decoration: underline;
        cursor: pointer;
    }

    &__toolbar {



    }

}

.burger-menu {

    padding: 0;
    margin: 0;


    &_level_1 {

        position: absolute;
        top: 0;
        left: 100%;
        height: 100%;
        min-width: 343px;
        padding: 172px 0 43px 40px; 
        background-color: #067999;
        opacity: 0; 
        visibility: collapse;
        box-sizing: border-box;
        transition: opacity .6s ease;
        overflow: hidden;

        @media screen and (min-width: 976px) {
            background-color: #067999;
        }

        @media screen and (max-width: 976px) {

            position: static;
            top: auto;
            right: auto;
            height: auto;
            padding: 0;
            height: 0;
            opacity: 1;
            visibility: visible;
            overflow: hidden;
            transition: .6s ease;

        }

        &_active{

            opacity: 1;
            visibility: visible;
            transition: opacity .6s ease;

        }
    }



    &__item {

        list-style-type: none;
        margin-bottom: 12px;

    }

    &__link {

        font-size: 19px;
        color: $color-white;
        font-stretch: ultra-condensed;
        display: block;
        padding: 8px 10px 8px 15px; 
        box-sizing: border-box;

        &:hover {

            background-color: #067999;


        }
    }
}
