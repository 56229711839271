@import url(/node_modules/perfect-scrollbar/css/perfect-scrollbar.css);
/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500");
.hamburger {
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible; }
  .hamburger:hover {
    opacity: 0.7; }
  .hamburger.is-active:hover {
    opacity: 0.7; }
  .hamburger.is-active .hamburger-inner,
  .hamburger.is-active .hamburger-inner::before,
  .hamburger.is-active .hamburger-inner::after {
    background-color: #000; }

.hamburger-box {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative; }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px; }
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 40px;
    height: 4px;
    background-color: #000;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; }
  .hamburger-inner::before, .hamburger-inner::after {
    content: "";
    display: block; }
  .hamburger-inner::before {
    top: -10px; }
  .hamburger-inner::after {
    bottom: -10px; }

/*
   * 3DX
   */
.hamburger--3dx .hamburger-box {
  perspective: 80px; }

.hamburger--3dx .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dx .hamburger-inner::before, .hamburger--3dx .hamburger-inner::after {
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dx.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateY(180deg); }
  .hamburger--3dx.is-active .hamburger-inner::before {
    transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--3dx.is-active .hamburger-inner::after {
    transform: translate3d(0, -10px, 0) rotate(-45deg); }

/*
   * 3DX Reverse
   */
.hamburger--3dx-r .hamburger-box {
  perspective: 80px; }

.hamburger--3dx-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dx-r .hamburger-inner::before, .hamburger--3dx-r .hamburger-inner::after {
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dx-r.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateY(-180deg); }
  .hamburger--3dx-r.is-active .hamburger-inner::before {
    transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--3dx-r.is-active .hamburger-inner::after {
    transform: translate3d(0, -10px, 0) rotate(-45deg); }

/*
   * 3DY
   */
.hamburger--3dy .hamburger-box {
  perspective: 80px; }

.hamburger--3dy .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dy .hamburger-inner::before, .hamburger--3dy .hamburger-inner::after {
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dy.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(-180deg); }
  .hamburger--3dy.is-active .hamburger-inner::before {
    transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--3dy.is-active .hamburger-inner::after {
    transform: translate3d(0, -10px, 0) rotate(-45deg); }

/*
   * 3DY Reverse
   */
.hamburger--3dy-r .hamburger-box {
  perspective: 80px; }

.hamburger--3dy-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dy-r .hamburger-inner::before, .hamburger--3dy-r .hamburger-inner::after {
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dy-r.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(180deg); }
  .hamburger--3dy-r.is-active .hamburger-inner::before {
    transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--3dy-r.is-active .hamburger-inner::after {
    transform: translate3d(0, -10px, 0) rotate(-45deg); }

/*
   * 3DXY
   */
.hamburger--3dxy .hamburger-box {
  perspective: 80px; }

.hamburger--3dxy .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dxy .hamburger-inner::before, .hamburger--3dxy .hamburger-inner::after {
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dxy.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(180deg) rotateY(180deg); }
  .hamburger--3dxy.is-active .hamburger-inner::before {
    transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--3dxy.is-active .hamburger-inner::after {
    transform: translate3d(0, -10px, 0) rotate(-45deg); }

/*
   * 3DXY Reverse
   */
.hamburger--3dxy-r .hamburger-box {
  perspective: 80px; }

.hamburger--3dxy-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dxy-r .hamburger-inner::before, .hamburger--3dxy-r .hamburger-inner::after {
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dxy-r.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(180deg) rotateY(180deg) rotateZ(-180deg); }
  .hamburger--3dxy-r.is-active .hamburger-inner::before {
    transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--3dxy-r.is-active .hamburger-inner::after {
    transform: translate3d(0, -10px, 0) rotate(-45deg); }

/*
   * Arrow
   */
.hamburger--arrow.is-active .hamburger-inner::before {
  transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1); }

.hamburger--arrow.is-active .hamburger-inner::after {
  transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1); }

/*
   * Arrow Right
   */
.hamburger--arrow-r.is-active .hamburger-inner::before {
  transform: translate3d(8px, 0, 0) rotate(45deg) scale(0.7, 1); }

.hamburger--arrow-r.is-active .hamburger-inner::after {
  transform: translate3d(8px, 0, 0) rotate(-45deg) scale(0.7, 1); }

/*
   * Arrow Alt
   */
.hamburger--arrowalt .hamburger-inner::before {
  transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt .hamburger-inner::after {
  transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt.is-active .hamburger-inner::before {
  top: 0;
  transform: translate3d(-8px, -10px, 0) rotate(-45deg) scale(0.7, 1);
  transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

.hamburger--arrowalt.is-active .hamburger-inner::after {
  bottom: 0;
  transform: translate3d(-8px, 10px, 0) rotate(45deg) scale(0.7, 1);
  transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

/*
   * Arrow Alt Right
   */
.hamburger--arrowalt-r .hamburger-inner::before {
  transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt-r .hamburger-inner::after {
  transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt-r.is-active .hamburger-inner::before {
  top: 0;
  transform: translate3d(8px, -10px, 0) rotate(45deg) scale(0.7, 1);
  transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

.hamburger--arrowalt-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: translate3d(8px, 10px, 0) rotate(-45deg) scale(0.7, 1);
  transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

/*
   * Arrow Turn
   */
.hamburger--arrowturn.is-active .hamburger-inner {
  transform: rotate(-180deg); }
  .hamburger--arrowturn.is-active .hamburger-inner::before {
    transform: translate3d(8px, 0, 0) rotate(45deg) scale(0.7, 1); }
  .hamburger--arrowturn.is-active .hamburger-inner::after {
    transform: translate3d(8px, 0, 0) rotate(-45deg) scale(0.7, 1); }

/*
   * Arrow Turn Right
   */
.hamburger--arrowturn-r.is-active .hamburger-inner {
  transform: rotate(-180deg); }
  .hamburger--arrowturn-r.is-active .hamburger-inner::before {
    transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1); }
  .hamburger--arrowturn-r.is-active .hamburger-inner::after {
    transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1); }

/*
   * Boring
   */
.hamburger--boring .hamburger-inner, .hamburger--boring .hamburger-inner::before, .hamburger--boring .hamburger-inner::after {
  transition-property: none; }

.hamburger--boring.is-active .hamburger-inner {
  transform: rotate(45deg); }
  .hamburger--boring.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0; }
  .hamburger--boring.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg); }

/*
   * Collapse
   */
.hamburger--collapse .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--collapse .hamburger-inner::after {
    top: -20px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear; }
  .hamburger--collapse .hamburger-inner::before {
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--collapse.is-active .hamburger-inner {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--collapse.is-active .hamburger-inner::after {
    top: 0;
    opacity: 0;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear; }
  .hamburger--collapse.is-active .hamburger-inner::before {
    top: 0;
    transform: rotate(-90deg);
    transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Collapse Reverse
   */
.hamburger--collapse-r .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--collapse-r .hamburger-inner::after {
    top: -20px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear; }
  .hamburger--collapse-r .hamburger-inner::before {
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--collapse-r.is-active .hamburger-inner {
  transform: translate3d(0, -10px, 0) rotate(45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--collapse-r.is-active .hamburger-inner::after {
    top: 0;
    opacity: 0;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear; }
  .hamburger--collapse-r.is-active .hamburger-inner::before {
    top: 0;
    transform: rotate(90deg);
    transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Elastic
   */
.hamburger--elastic .hamburger-inner {
  top: 2px;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }
  .hamburger--elastic .hamburger-inner::before {
    top: 10px;
    transition: opacity 0.125s 0.275s ease; }
  .hamburger--elastic .hamburger-inner::after {
    top: 20px;
    transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55); }

.hamburger--elastic.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(135deg);
  transition-delay: 0.075s; }
  .hamburger--elastic.is-active .hamburger-inner::before {
    transition-delay: 0s;
    opacity: 0; }
  .hamburger--elastic.is-active .hamburger-inner::after {
    transform: translate3d(0, -20px, 0) rotate(-270deg);
    transition-delay: 0.075s; }

/*
   * Elastic Reverse
   */
.hamburger--elastic-r .hamburger-inner {
  top: 2px;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }
  .hamburger--elastic-r .hamburger-inner::before {
    top: 10px;
    transition: opacity 0.125s 0.275s ease; }
  .hamburger--elastic-r .hamburger-inner::after {
    top: 20px;
    transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55); }

.hamburger--elastic-r.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(-135deg);
  transition-delay: 0.075s; }
  .hamburger--elastic-r.is-active .hamburger-inner::before {
    transition-delay: 0s;
    opacity: 0; }
  .hamburger--elastic-r.is-active .hamburger-inner::after {
    transform: translate3d(0, -20px, 0) rotate(270deg);
    transition-delay: 0.075s; }

/*
   * Emphatic
   */
.hamburger--emphatic {
  overflow: hidden; }
  .hamburger--emphatic .hamburger-inner {
    transition: background-color 0.125s 0.175s ease-in; }
    .hamburger--emphatic .hamburger-inner::before {
      left: 0;
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in; }
    .hamburger--emphatic .hamburger-inner::after {
      top: 10px;
      right: 0;
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in; }
  .hamburger--emphatic.is-active .hamburger-inner {
    transition-delay: 0s;
    transition-timing-function: ease-out;
    background-color: transparent !important; }
    .hamburger--emphatic.is-active .hamburger-inner::before {
      left: -80px;
      top: -80px;
      transform: translate3d(80px, 80px, 0) rotate(45deg);
      transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }
    .hamburger--emphatic.is-active .hamburger-inner::after {
      right: -80px;
      top: -80px;
      transform: translate3d(-80px, 80px, 0) rotate(-45deg);
      transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }

/*
   * Emphatic Reverse
   */
.hamburger--emphatic-r {
  overflow: hidden; }
  .hamburger--emphatic-r .hamburger-inner {
    transition: background-color 0.125s 0.175s ease-in; }
    .hamburger--emphatic-r .hamburger-inner::before {
      left: 0;
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in; }
    .hamburger--emphatic-r .hamburger-inner::after {
      top: 10px;
      right: 0;
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in; }
  .hamburger--emphatic-r.is-active .hamburger-inner {
    transition-delay: 0s;
    transition-timing-function: ease-out;
    background-color: transparent !important; }
    .hamburger--emphatic-r.is-active .hamburger-inner::before {
      left: -80px;
      top: 80px;
      transform: translate3d(80px, -80px, 0) rotate(-45deg);
      transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }
    .hamburger--emphatic-r.is-active .hamburger-inner::after {
      right: -80px;
      top: 80px;
      transform: translate3d(-80px, -80px, 0) rotate(45deg);
      transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }

/*
   * Minus
   */
.hamburger--minus .hamburger-inner::before, .hamburger--minus .hamburger-inner::after {
  transition: bottom 0.08s 0s ease-out, top 0.08s 0s ease-out, opacity 0s linear; }

.hamburger--minus.is-active .hamburger-inner::before, .hamburger--minus.is-active .hamburger-inner::after {
  opacity: 0;
  transition: bottom 0.08s ease-out, top 0.08s ease-out, opacity 0s 0.08s linear; }

.hamburger--minus.is-active .hamburger-inner::before {
  top: 0; }

.hamburger--minus.is-active .hamburger-inner::after {
  bottom: 0; }

/*
   * Slider
   */
.hamburger--slider .hamburger-inner {
  top: 2px; }
  .hamburger--slider .hamburger-inner::before {
    top: 10px;
    transition-property: transform, opacity;
    transition-timing-function: ease;
    transition-duration: 0.15s; }
  .hamburger--slider .hamburger-inner::after {
    top: 20px; }

.hamburger--slider.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--slider.is-active .hamburger-inner::before {
    transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
    opacity: 0; }
  .hamburger--slider.is-active .hamburger-inner::after {
    transform: translate3d(0, -20px, 0) rotate(-90deg); }

/*
   * Slider Reverse
   */
.hamburger--slider-r .hamburger-inner {
  top: 2px; }
  .hamburger--slider-r .hamburger-inner::before {
    top: 10px;
    transition-property: transform, opacity;
    transition-timing-function: ease;
    transition-duration: 0.15s; }
  .hamburger--slider-r .hamburger-inner::after {
    top: 20px; }

.hamburger--slider-r.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(-45deg); }
  .hamburger--slider-r.is-active .hamburger-inner::before {
    transform: rotate(45deg) translate3d(5.71429px, -6px, 0);
    opacity: 0; }
  .hamburger--slider-r.is-active .hamburger-inner::after {
    transform: translate3d(0, -20px, 0) rotate(90deg); }

/*
   * Spin
   */
.hamburger--spin .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spin .hamburger-inner::before {
    transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in; }
  .hamburger--spin .hamburger-inner::after {
    transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spin.is-active .hamburger-inner {
  transform: rotate(225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--spin.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out; }
  .hamburger--spin.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Spin Reverse
   */
.hamburger--spin-r .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spin-r .hamburger-inner::before {
    transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in; }
  .hamburger--spin-r .hamburger-inner::after {
    transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spin-r.is-active .hamburger-inner {
  transform: rotate(-225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--spin-r.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out; }
  .hamburger--spin-r.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(90deg);
    transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Spring
   */
.hamburger--spring .hamburger-inner {
  top: 2px;
  transition: background-color 0s 0.13s linear; }
  .hamburger--spring .hamburger-inner::before {
    top: 10px;
    transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spring .hamburger-inner::after {
    top: 20px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spring.is-active .hamburger-inner {
  transition-delay: 0.22s;
  background-color: transparent !important; }
  .hamburger--spring.is-active .hamburger-inner::before {
    top: 0;
    transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--spring.is-active .hamburger-inner::after {
    top: 0;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 10px, 0) rotate(-45deg); }

/*
   * Spring Reverse
   */
.hamburger--spring-r .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spring-r .hamburger-inner::after {
    top: -20px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0s linear; }
  .hamburger--spring-r .hamburger-inner::before {
    transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spring-r.is-active .hamburger-inner {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--spring-r.is-active .hamburger-inner::after {
    top: 0;
    opacity: 0;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0s 0.22s linear; }
  .hamburger--spring-r.is-active .hamburger-inner::before {
    top: 0;
    transform: rotate(90deg);
    transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Stand
   */
.hamburger--stand .hamburger-inner {
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear; }
  .hamburger--stand .hamburger-inner::before {
    transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--stand .hamburger-inner::after {
    transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--stand.is-active .hamburger-inner {
  transform: rotate(90deg);
  background-color: transparent !important;
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear; }
  .hamburger--stand.is-active .hamburger-inner::before {
    top: 0;
    transform: rotate(-45deg);
    transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--stand.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(45deg);
    transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Stand Reverse
   */
.hamburger--stand-r .hamburger-inner {
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear; }
  .hamburger--stand-r .hamburger-inner::before {
    transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--stand-r .hamburger-inner::after {
    transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--stand-r.is-active .hamburger-inner {
  transform: rotate(-90deg);
  background-color: transparent !important;
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear; }
  .hamburger--stand-r.is-active .hamburger-inner::before {
    top: 0;
    transform: rotate(-45deg);
    transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--stand-r.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(45deg);
    transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Squeeze
   */
.hamburger--squeeze .hamburger-inner {
  transition-duration: 0.075s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--squeeze .hamburger-inner::before {
    transition: top 0.075s 0.12s ease, opacity 0.075s ease; }
  .hamburger--squeeze .hamburger-inner::after {
    transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--squeeze.is-active .hamburger-inner {
  transform: rotate(45deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--squeeze.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.075s ease, opacity 0.075s 0.12s ease; }
  .hamburger--squeeze.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Vortex
   */
.hamburger--vortex .hamburger-inner {
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  .hamburger--vortex .hamburger-inner::before, .hamburger--vortex .hamburger-inner::after {
    transition-duration: 0s;
    transition-delay: 0.1s;
    transition-timing-function: linear; }
  .hamburger--vortex .hamburger-inner::before {
    transition-property: top, opacity; }
  .hamburger--vortex .hamburger-inner::after {
    transition-property: bottom, transform; }

.hamburger--vortex.is-active .hamburger-inner {
  transform: rotate(765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  .hamburger--vortex.is-active .hamburger-inner::before, .hamburger--vortex.is-active .hamburger-inner::after {
    transition-delay: 0s; }
  .hamburger--vortex.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0; }
  .hamburger--vortex.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(90deg); }

/*
   * Vortex Reverse
   */
.hamburger--vortex-r .hamburger-inner {
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  .hamburger--vortex-r .hamburger-inner::before, .hamburger--vortex-r .hamburger-inner::after {
    transition-duration: 0s;
    transition-delay: 0.1s;
    transition-timing-function: linear; }
  .hamburger--vortex-r .hamburger-inner::before {
    transition-property: top, opacity; }
  .hamburger--vortex-r .hamburger-inner::after {
    transition-property: bottom, transform; }

.hamburger--vortex-r.is-active .hamburger-inner {
  transform: rotate(-765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  .hamburger--vortex-r.is-active .hamburger-inner::before, .hamburger--vortex-r.is-active .hamburger-inner::after {
    transition-delay: 0s; }
  .hamburger--vortex-r.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0; }
  .hamburger--vortex-r.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg); }

html {
  height: 100%; }

.body {
  margin: 0;
  padding: 0;
  position: relative !important;
  height: 100%;
  top: 0 !important;
  font-family: "Roboto", sans-serif; }

.main {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center; }
  .main__wrapper {
    width: 100%;
    height: 100%; }
  .main__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px; }

.p-text {
  font-size: 14px;
  color: #707070;
  line-height: 1.71;
  margin: 0 0 25px; }

.header {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden; }
  .header_inner-page {
    background: url("/images/bg-about.jpg") no-repeat center;
    background-size: cover; }
    .header_inner-page .header__wrapper {
      flex-direction: column;
      padding: 50px 50px 130px 50px; }
  .header_cyan {
    background: none; }
    .header_cyan .header__wrapper {
      padding: 50px 50px 20px 50px; }
    .header_cyan .menu__link, .header_cyan .header-links__text, .header_cyan .header-language-selection__language, .header_cyan .header-main__title {
      color: #3f3e33; }
    .header_cyan .hamburger-inner {
      background-color: #767676 !important; }
      .header_cyan .hamburger-inner:after, .header_cyan .hamburger-inner:before {
        background-color: #767676 !important; }
    .header_cyan .header-toolbar__search {
      background-image: url("/images/icons/search_inner-page.svg"); }
    .header_cyan .logo {
      background-image: url("/images/logo_cyan.png"); }
  .header__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1920px;
    width: 100%;
    padding: 50px 50px;
    z-index: 3; }
    @media screen and (max-height: 786px) {
      .header__wrapper {
        padding: 20px 50px; } }
  .header__top-line {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 120px; }
  .header__video-bg {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    height: auto; }

.header-main {
  max-width: 1120px;
  width: 100%; }
  .header-main__links {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 38px; }
  .header-main__link {
    padding: 6px 7px;
    box-sizing: border-box;
    background-color: #01a1cf;
    text-decoration: underline;
    color: #FFF;
    font-size: 15px;
    border-radius: 4px;
    text-align: left;
    margin-bottom: 15px;
    margin-right: 4px; }
    .header-main__link_active {
      background-color: #FFF;
      color: #01a1cf;
      border: 1px solid #01a1cf; }
    .header-main__link:last-chiild {
      margin-right: 0; }
  .header-main__title {
    font-size: 60px;
    color: #FFF;
    letter-spacing: 0.1px;
    margin-bottom: 27px;
    font-stretch: ultra-condensed; }
    @media screen and (max-width: 1156px) {
      .header-main__title {
        font-size: 32px; } }
  .header-main__text {
    font-size: 18px;
    color: #FFF; }
  .header-main__nav {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap; }
  .header-main__nav-link {
    max-width: 200px;
    padding: 6px 7px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box;
    border: 1px solid #01a1cf;
    border-radius: 4px;
    color: #01b9db;
    font-size: 18px;
    margin-right: 15px;
    margin-bottom: 15px;
    transition: .6s ease; }
    .header-main__nav-link:hover {
      transition: 1.5s ease;
      white-space: normal; }
    .header-main__nav-link_active {
      font-weight: bold;
      text-decoration: none; }
    .header-main__nav-link:last-child {
      margin-right: 0; }

.header-toolbar {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 50px;
  z-index: 12; }
  @media screen and (max-width: 976px) {
    .header-toolbar {
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      margin-right: 0; } }
  .header-toolbar__menu-btn {
    padding: 0;
    cursor: pointer; }
    .header-toolbar__menu-btn .hamburger-box {
      width: 20px; }
    .header-toolbar__menu-btn .hamburger-inner, .header-toolbar__menu-btn .hamburger-inner:before, .header-toolbar__menu-btn .hamburger-inner:after {
      width: 100%;
      background-color: #fff;
      height: 2px; }
    .header-toolbar__menu-btn .hamburger-inner:before {
      top: -6px; }
    .header-toolbar__menu-btn .hamburger-inner:after {
      bottom: -6px; }
  .header-toolbar__search {
    width: 28px;
    height: 16px;
    background: url("/images/icons/search.svg") no-repeat center;
    background-size: contain;
    cursor: pointer;
    margin-bottom: 7px; }
  .header-toolbar__accessibility {
    width: 30px;
    height: 21px;
    background: url("/images/icons/accessibility.svg") no-repeat center;
    background-size: contain;
    cursor: pointer; }
    @media screen and (max-width: 976px) {
      .header-toolbar__accessibility {
        display: none; } }

.header-links {
  display: flex;
  align-items: center;
  flex-grow: 1;
  margin-right: 25px;
  z-index: 3; }
  @media screen and (max-width: 976px) {
    .header-links {
      display: none; } }
  .header-links__link {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    flex-grow: 1;
    z-index: 2; }
    .header-links__link:hover {
      text-decoration: none; }
    .header-links__link_image {
      max-width: 214px;
      width: 100%;
      height: 69px;
      background: url("/images/header-links__image2.png") no-repeat center;
      background-size: contain; }
  .header-links__image {
    max-width: 57px;
    width: 100%;
    height: 50px;
    background: url("/images/header-links__image.png") no-repeat center;
    background-size: contain;
    margin-right: 25px; }
  .header-links__text {
    color: #fff;
    max-width: 200px;
    width: 100%;
    font: 12px medium "Roboto", sans-serif;
    text-decoration: underline; }
    .header-links__text:hover {
      text-decoration: none; }

.header-language-selection {
  display: flex;
  flex-direction: column;
  z-index: 3; }
  @media screen and (max-width: 976px) {
    .header-language-selection {
      display: none; } }
  .header-language-selection__language {
    color: #fff;
    background-color: transparent;
    border: none; }
    .header-language-selection__language:first-child {
      margin-bottom: 12px; }

.menu {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0 25px 0 0;
  flex-wrap: wrap;
  z-index: 3; }
  @media screen and (max-width: 1636px) {
    .menu {
      max-width: 500px; } }
  @media screen and (max-width: 1456px) {
    .menu {
      max-width: 340px; } }
  @media screen and (max-width: 976px) {
    .menu {
      display: none; } }
  .menu__item {
    list-style-type: none;
    margin-right: 25px; }
    .menu__item:last-child {
      margin-right: 0; }
  .menu__link {
    color: #fff;
    font-stretch: ultra-condensed; }
    .menu__link:hover {
      text-decoration: none; }
    .menu__link_active {
      text-decoration: none; }

.burger {
  top: 0;
  left: 0;
  margin: 0;
  padding: 50px 20px 43px 50px;
  box-sizing: border-box;
  position: fixed;
  background-color: #00a1ce;
  z-index: 11;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transform: translateX(-100%);
  transition: transform .6s ease; }
  @media screen and (max-width: 976px) {
    .burger {
      transform: translateX(0);
      transform: translateY(-100%);
      width: 100%;
      padding: 50px 50px 43px 50px; } }
  .burger_active {
    transform: translateX(0);
    transition: transform .6s ease; }
    @media screen and (max-width: 976px) {
      .burger_active {
        transform: translateY(0); } }
  .burger__wrapper {
    display: flex;
    align-items: center; }
  .burger__feedback {
    max-width: 230px; }
  .burger__text {
    font-size: 15px;
    color: #FFF;
    margin-bottom: 10px; }
  .burger__text a {
    color: #FFF; }
  .burger__button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 10px;
    border: 1px solid #FFF;
    border-radius: 6px;
    color: #FFF;
    font-size: 15px;
    text-decoration: underline;
    cursor: pointer; }
  .burger__site-map {
    color: #FFF;
    font-size: 15px;
    text-decoration: underline;
    cursor: pointer; }

.burger-menu {
  padding: 0;
  margin: 0; }
  .burger-menu_level_1 {
    position: absolute;
    top: 0;
    left: 100%;
    height: 100%;
    min-width: 343px;
    padding: 172px 0 43px 40px;
    background-color: #067999;
    opacity: 0;
    visibility: collapse;
    box-sizing: border-box;
    transition: opacity .6s ease;
    overflow: hidden; }
    @media screen and (min-width: 976px) {
      .burger-menu_level_1 {
        background-color: #067999; } }
    @media screen and (max-width: 976px) {
      .burger-menu_level_1 {
        position: static;
        top: auto;
        right: auto;
        height: auto;
        padding: 0;
        height: 0;
        opacity: 1;
        visibility: visible;
        overflow: hidden;
        transition: .6s ease; } }
    .burger-menu_level_1_active {
      opacity: 1;
      visibility: visible;
      transition: opacity .6s ease; }
  .burger-menu__item {
    list-style-type: none;
    margin-bottom: 12px; }
  .burger-menu__link {
    font-size: 19px;
    color: #fff;
    font-stretch: ultra-condensed;
    display: block;
    padding: 8px 10px 8px 15px;
    box-sizing: border-box; }
    .burger-menu__link:hover {
      background-color: #067999; }

.footer {
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 3;
  position: absolute;
  bottom: 0; }
  .footer_inner-page {
    position: static;
    bottom: auto;
    border-top: 116px solid transparent;
    border-image: url("/images/footer-border.png") 100% 0% 0%/1 0 0 round; }
    .footer_inner-page .footer__logo, .footer_inner-page .footer__logo-unesco, .footer_inner-page .footer__logo-koygorodskiy {
      background-image: url("/images/footer__logo_cyan.png"); }
    .footer_inner-page .footer__logo-unesco {
      background-image: url("/images/logo-unesko-cyan.png"); }
    .footer_inner-page .footer__logo-koygorodskiy {
      background-image: url("/images/logo-koygorodskiy-cyan.png"); }
    .footer_inner-page .footer__copyrite {
      color: #535353; }
      .footer_inner-page .footer__copyrite a {
        text-decoration: underline;
        color: #535353; }
        .footer_inner-page .footer__copyrite a:hover {
          text-decoration: none; }
    .footer_inner-page .footer__link {
      color: #535353; }
    .footer_inner-page .footer-social-networks__link {
      border-color: #01b9db; }
    .footer_inner-page .footer-social-networks__title {
      color: #535353; }
    .footer_inner-page .footer-social-networks__icon_facebook {
      background-image: url("/images/icons/facebook-cyan.svg"); }
    .footer_inner-page .footer-social-networks__icon_classmates {
      background-image: url("/images/icons/classmates-cyan.svg"); }
    .footer_inner-page .footer-social-networks__icon_instagram {
      background-image: url("/images/icons/instagram-cyan.svg"); }
    .footer_inner-page .footer-social-networks__icon_vk {
      background-image: url("/images/icons/vk-cyan.svg"); }
  .footer__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1820px;
    width: 100%;
    padding: 50px;
    flex-direction: row-reverse; }
    @media screen and (max-width: 976px) {
      .footer__wrapper {
        padding: 20px 36px; } }
  .footer__copyrite {
    color: #fff;
    font-size: 14px;
    max-width: 173px;
    margin-right: 70px; }
    @media screen and (max-width: 976px) {
      .footer__copyrite {
        display: none; } }
    .footer__copyrite a {
      text-decoration: underline;
      color: #fff; }
      .footer__copyrite a:hover {
        text-decoration: none; }
  .footer__logo, .footer__logo-unesco, .footer__logo-koygorodskiy {
    max-width: 121px;
    height: 50px;
    width: 100%;
    background: url("/images/footer__logo.png") no-repeat center;
    background-size: contain; }
    @media screen and (max-width: 976px) {
      .footer__logo, .footer__logo-unesco, .footer__logo-koygorodskiy {
        display: none; } }
    .footer__logo-unesco {
      background-image: url("/images/logo-unesko.png"); }
    .footer__logo-koygorodskiy {
      background-image: url("/images/logo-koygorodskiy.png"); }
  .footer__link {
    color: #fff; }
    @media screen and (max-width: 976px) {
      .footer__link {
        display: none; } }

.footer-social-networks {
  display: flex;
  flex-direction: column; }
  .footer-social-networks__title {
    color: #fff;
    margin-bottom: 20px; }
    @media screen and (max-width: 976px) {
      .footer-social-networks__title {
        display: none; } }
  .footer-social-networks__wrapper {
    display: flex;
    align-items: center; }
  .footer-social-networks__link {
    margin-right: 20px;
    border: 3px solid #fff;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px;
    box-sizing: border-box;
    max-width: 31px;
    max-height: 31px;
    width: 100%;
    height: 100%; }
    .footer-social-networks__link_instagram {
      border: none;
      padding: 0; }
    .footer-social-networks__link_inner-page {
      border-color: #01b9db; }
    .footer-social-networks__link:last-child {
      margin-right: 0; }
  .footer-social-networks__icon_facebook {
    width: 18px;
    height: 15px;
    background: url("/images/icons/facebook.svg") no-repeat center;
    background-size: contain; }
    .footer-social-networks__icon_facebook_inner-page {
      background-image: url("/images/icons/facebook-cyan.svg"); }
  .footer-social-networks__icon_classmates {
    width: 10px;
    height: 15px;
    background: url("/images/icons/classmates.svg") no-repeat center;
    background-size: contain; }
    .footer-social-networks__icon_classmates_inner-page {
      background-image: url("/images/icons/classmates-cyan.svg"); }
  .footer-social-networks__icon_instagram {
    width: 31px;
    height: 31px;
    background: url("/images/icons/instagram.svg") no-repeat center;
    background-size: contain; }
    .footer-social-networks__icon_instagram_inner-page {
      background-image: url("/images/icons/instagram-cyan.svg"); }
  .footer-social-networks__icon_vk {
    width: 13px;
    height: 15px;
    background: url("/images/icons/vk.svg") no-repeat center;
    background-size: contain; }
    .footer-social-networks__icon_vk_inner-page {
      background-image: url("/images/icons/vk-cyan.svg"); }

.button {
  display: flex;
  cursor: pointer; }
  .button_cyan, .button_write-us {
    justify-content: center;
    align-items: center;
    max-width: 230px;
    width: 100%;
    color: #fff;
    box-sizing: border-box;
    padding: 20px 10px;
    background-color: #01b9db;
    border: 1px solid #01b9db;
    border-radius: 6px;
    margin-right: 20px;
    text-align: center; }
  .button_transition {
    justify-content: center;
    align-items: center;
    max-width: 230px;
    width: 100%;
    color: #fff;
    box-sizing: border-box;
    padding: 20px 10px;
    text-decoration: underline;
    border: 1px solid #fff;
    border-radius: 6px;
    text-align: center; }
    @media screen and (max-width: 976px) {
      .button_transition {
        max-width: 135px;
        border-radius: 5px;
        background-color: #01a1cf;
        border: none;
        padding: 10px;
        margin: 0 auto;
        text-decoration: none; } }
  .button_write-us {
    max-width: 300px;
    padding: 15px;
    margin: 0 0 65px 0; }
    .button_write-us:before {
      content: '';
      display: block;
      width: 19px;
      height: 18px;
      background: url("/images/icons/write-us.svg") no-repeat center;
      background-size: contain;
      margin-right: 14px; }

.link__main {
  color: white;
  transition: all .3s ease-in-out; }
  .link__main:hover {
    text-decoration: none;
    transition: all .3s ease-in-out; }

.popup-wrapper {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 15px;
  box-sizing: border-box;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  background-color: #fdbf2e !important;
  text-align: center; }

.feedback-popup {
  display: flex;
  flex-direction: column;
  max-width: 940px;
  width: 100%;
  height: 540px;
  padding: 110px 130px;
  box-sizing: border-box;
  background: url("/images/popup-bg.jpg"); }
  @media screen and (max-width: 656px) {
    .feedback-popup {
      padding: 15px; } }
  .feedback-popup__title {
    font-size: 25px;
    color: #413535;
    margin-bottom: 2px; }
  .feedback-popup__description {
    font-size: 18px;
    color: #413535;
    margin-bottom: 33px; }
  .feedback-popup__column {
    display: flex;
    flex-direction: column;
    max-width: 340px;
    width: 100%; }
  .feedback-popup__input {
    width: 100%;
    padding: 18px 38px;
    box-sizing: border-box;
    margin-bottom: 11px;
    border: solid 1px #707070;
    border-radius: 4px; }
  .feedback-popup__row {
    display: flex;
    margin-bottom: 26px; }
  .feedback-popup__checkbox {
    margin-right: 8px;
    align-self: flex-start; }
  .feedback-popup__text {
    max-width: 280px; }
  .feedback-popup__button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 5px;
    color: #fff;
    box-sizing: border-box;
    max-width: 210px;
    width: 100%;
    border: solid 1px #707070;
    border-radius: 4px;
    background-color: #01a1cf;
    cursor: pointer; }

.partners-item {
  display: flex;
  align-items: center;
  margin-bottom: 60px;
  display: flex;
  margin-bottom: 60px; }
  .partners-item:last-child {
    margin: 0; }
  .partners-item__image-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 220px;
    width: 100%;
    min-height: 220px;
    border: solid 1px #bfbfbf;
    box-sizing: border-box;
    padding: 18px;
    margin-right: 50px;
    flex-shrink: 0; }
  .partners-item:last-child {
    margin: 0; }
  .partners-item__image {
    max-width: 184px;
    max-height: 180px;
    width: 100px;
    height: 100px;
    background: url("/images/partner.png") no-repeat center;
    background-size: contain; }
  .partners-item__wrapper {
    display: flex;
    flex-direction: column; }
  .partners-item__title {
    font-size: 24px;
    color: #3f3e33; }

.news-item {
  display: flex;
  margin-bottom: 60px; }
  .news-item:last-child {
    margin: 0; }
  .news-item__image {
    width: 100px;
    height: 100px;
    background: url("/images/news-item2.jpg") no-repeat center;
    background-size: contain;
    margin-right: 50px;
    flex-shrink: 0; }
  .news-item__wrapper {
    display: flex;
    flex-direction: column; }
  .news-item__date {
    font-size: 14px;
    color: #767676;
    margin-bottom: 10px; }
  .news-item__title {
    font-size: 18px;
    color: #4e4e4e;
    margin-bottom: 10px; }
  .news-item__link {
    font-size: 14px;
    color: #01b9db; }

.documents-item {
  display: flex;
  margin-bottom: 60px; }
  .documents-item:last-child {
    margin: 0; }
  .documents-item__image {
    width: 100px;
    height: 100px;
    background: url("/images/sprites/word.png") no-repeat center;
    background-size: contain;
    margin-right: 50px;
    flex-shrink: 0; }
  .documents-item__wrapper {
    display: flex;
    flex-direction: column; }
  .documents-item__date {
    font-size: 14px;
    color: #767676;
    margin-bottom: 10px; }
  .documents-item__title {
    font-size: 18px;
    color: #4e4e4e;
    margin-bottom: 10px; }
  .documents-item__link {
    font-size: 14px;
    color: #01b9db; }

.logo {
  max-width: 86px;
  width: 100%;
  height: 50px;
  margin-right: 70px;
  background: url("/images/logo.png") no-repeat center;
  background-size: contain;
  z-index: 12; }
  @media screen and (max-width: 976px) {
    .logo {
      display: none; } }
  .logo_header {
    display: none; }
    @media screen and (max-width: 976px) {
      .logo_header {
        display: block;
        margin-right: 0; } }

a.gflag {
  position: absolute;
  top: 0;
  z-index: 40;
  vertical-align: middle;
  font-size: 16px;
  padding: 1px 0;
  background-repeat: no-repeat;
  background-image: url(//gtranslate.net/flags/16.png); }

a.gflag img {
  border: 0; }

a.gflag:hover {
  background-image: url(//gtranslate.net/flags/16a.png); }

#goog-gt-tt {
  display: none !important; }

.goog-te-banner-frame {
  display: none !important; }

.goog-te-menu-value:hover {
  text-decoration: none !important; }

#google_translate_element2 {
  display: none !important; }

.pages {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px; }

.left-arrow, .right-arrow {
  text-decoration: none; }

.left-arrow:before {
  display: block;
  content: "\2039";
  box-sizing: border-box; }

.right-arrow:after {
  box-sizing: border-box;
  display: block;
  content: "\203A"; }

.page {
  color: black;
  padding: 5px; }

.active {
  text-decoration: none; }

.swiper-main {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh; }
  .swiper-main__wrapper {
    display: inline-flex;
    height: 100%; }
  .swiper-main__slide {
    width: 100%;
    height: 100%;
    background: url("/images/bg-main.jpg") no-repeat center;
    background-size: cover; }
  .swiper-main__pagination {
    position: absolute;
    bottom: 110px;
    right: 750px;
    margiin: 0 auto;
    z-index: 4;
    display: flex;
    height: 24px; }
    @media screen and (max-width: 976px) {
      .swiper-main__pagination {
        display: none; } }
    .swiper-main__pagination .swiper-pagination-bullet {
      width: 14px;
      height: 14px;
      background-color: #fff;
      border-radius: 50%;
      margin-right: 45px;
      position: relative; }
      .swiper-main__pagination .swiper-pagination-bullet:after {
        content: '';
        position: absolute;
        width: 24px;
        height: 24px;
        background-color: transparent;
        border: 1px solid #fff;
        border-radius: 50%;
        top: -6px;
        left: -6px;
        opacity: 0;
        visibility: collapse;
        transition: .6s ease; }
      .swiper-main__pagination .swiper-pagination-bullet-active:after {
        opacity: 1;
        visibility: visible;
        transition: .6s ease; }
      .swiper-main__pagination .swiper-pagination-bullet:last-child {
        margin-right: 0; }
  .swiper-main__next, .swiper-main__prev {
    width: 78px;
    height: 78px;
    background: #767676 no-repeat center;
    border: 1px solid #707070;
    border-radius: 50%;
    cursor: pointer;
    top: 45%;
    z-index: 7;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center; }
    .swiper-main__next:after, .swiper-main__prev:after {
      content: '';
      position: absolute;
      background: url("/images/icons/arrow-left.svg") no-repeat center;
      background-size: contain;
      width: 20px;
      height: 20px; }
    @media screen and (max-width: 976px) {
      .swiper-main__next, .swiper-main__prev {
        display: none; } }
  .swiper-main__prev {
    left: 100px; }
  .swiper-main__next {
    transform: rotateY(180deg);
    right: 100px; }

.swiper-slide-active {
  z-index: 2; }

.swiper-main-slide:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2; }

.swiper-main-slide__wrapper {
  position: relative;
  padding: 275px 650px 275px 215px;
  z-index: 5;
  height: 100%;
  box-sizing: border-box; }
  @media screen and (max-height: 786px) {
    .swiper-main-slide__wrapper {
      padding: 215px 620px 275px 215px; } }
  @media screen and (max-width: 1236px) {
    .swiper-main-slide__wrapper {
      padding: 175px 215px 175px 215px; } }
  @media screen and (max-width: 976px) {
    .swiper-main-slide__wrapper {
      padding: 125px 85px 125px 85px; } }

.swiper-main-slide__title {
  font-size: 30px;
  color: #fff;
  margin-bottom: 40px; }
  @media screen and (max-width: 1516px) {
    .swiper-main-slide__title {
      font-size: 24px; } }
  @media screen and (max-width: 976px) {
    .swiper-main-slide__title {
      display: none; } }

.swiper-main-slide__title-mobile {
  display: none; }
  .swiper-main-slide__title-mobile-description {
    font-size: 16px;
    margin-bottom: 15px;
    color: #fff;
    letter-spacing: 1.6px;
    text-shadow: 0 3px 6px #000000fd; }
  .swiper-main-slide__title-mobile-main {
    font-size: 48px;
    font-weight: 300;
    color: #fff;
    letter-spacing: 1.6px;
    text-shadow: 0 3px 6px #000000fd; }
  @media screen and (max-width: 976px) {
    .swiper-main-slide__title-mobile {
      display: flex;
      flex-direction: column;
      align-items: center; } }

.swiper-main-slide__acent {
  font-size: 72px;
  color: #fff;
  margin-bottom: 28px; }
  @media screen and (max-width: 1516px) {
    .swiper-main-slide__acent {
      font-size: 36px; } }
  @media screen and (max-width: 976px) {
    .swiper-main-slide__acent {
      display: none; } }

.swiper-main-slide__description {
  font-size: 30px;
  color: #fff;
  margin-bottom: 70px;
  line-height: 1.25;
  text-shadow: 0 3px 6px #000000; }
  @media screen and (max-width: 1516px) {
    .swiper-main-slide__description {
      font-size: 20px; } }
  @media screen and (max-width: 976px) {
    .swiper-main-slide__description {
      font-size: 16px; } }

.swiper-main-slide__buttons {
  display: flex; }
  @media screen and (max-width: 976px) {
    .swiper-main-slide__buttons .button_cyan, .swiper-main-slide__buttons .button_write-us {
      display: none; } }

.main-news {
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
  max-width: 630px;
  width: 100%;
  z-index: 6;
  padding-right: 190px;
  box-sizing: border-box; }
  @media screen and (max-width: 1236px) {
    .main-news {
      display: none; } }
  .main-news__top-section {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
    padding-left: 35px; }
  .main-news__bottom-section {
    display: flex;
    flex-direction: column; }
  .main-news__wrapper {
    display: flex;
    flex-direction: column;
    text-decoration: none; }
    .main-news__wrapper_scroll {
      max-height: 270px;
      height: 100%;
      overflow: auto;
      position: relative;
      /* SCROLL STYLES */ }
      .main-news__wrapper_scroll:hover .ps__rail-y {
        opacity: 1; }
      @media screen and (max-height: 846px) {
        .main-news__wrapper_scroll {
          max-height: 206px; } }
      .main-news__wrapper_scroll .ps__rail-y {
        right: auto;
        left: 0;
        opacity: 0.7;
        background-color: transparent !important;
        cursor: pointer; }
        .main-news__wrapper_scroll .ps__rail-y:before {
          content: '';
          position: absolute;
          height: 100%;
          width: 2px;
          background-color: #fff;
          opacity: 0.8;
          left: 3px; }
      .main-news__wrapper_scroll .ps__thumb-y {
        background-color: #FFF;
        width: 8px;
        right: auto; }
  .main-news__title {
    color: #fff;
    margin-bottom: 33px; }
    .main-news__title_big {
      font-size: 30px;
      text-decoration: underline; }
      .main-news__title_big:hover {
        text-decoration: none; }
    .main-news__title_small {
      font-size: 18px;
      font-weight: 500; }

.main-news-item {
  display: flex;
  margin-bottom: 25px;
  text-decoration: none;
  flex-shrink: 0; }
  .main-news-item_scroll {
    padding-left: 35px; }
  .main-news-item:last-child {
    margin-bottom: 0; }
  .main-news-item__image {
    max-width: 90px;
    width: 100%;
    height: 90px;
    margin-right: 2px;
    flex-shrink: 0;
    background: url("/images/news-item.jpg") no-repeat center;
    background-size: cover;
    margin-right: 25px;
    border-radius: 10px; }
  .main-news-item__wrapper {
    display: flex;
    flex-direction: column; }
  .main-news-item__title {
    margin-bottom: 12px;
    color: #fff;
    font-size: 15px;
    text-decoration: underline; }
    .main-news-item__title:hover {
      text-decoration: none; }
  .main-news-item__description {
    margin-bottom: 18px;
    color: #fff;
    font-size: 18px;
    text-decoration: underline; }
    .main-news-item__description:hover {
      text-decoration: none; }
  .main-news-item__date {
    color: #fff;
    font-size: 14px; }

.about {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center; }
  .about__wrapper {
    max-width: 1120px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 70px 0;
    box-sizing: border-box; }
  .about__content {
    width: 100%;
    margin-right: 25px; }
    @media screen and (max-width: 1156px) {
      .about__content {
        margin-right: 0;
        padding: 0 15px; } }

.about-main__text-title {
  font-size: 25px;
  font-stretch: ultra-condensed;
  line-height: 36px;
  color: #3f3e33;
  margin-bottom: 30px; }

.about-main__content {
  color: #3f3e33;
  font-size: 17px;
  line-height: 30px; }

.about-main-slider {
  max-width: 752px;
  width: 100%;
  margin-bottom: 80px;
  height: 330px;
  overflow: hidden;
  position: relative; }
  .about-main-slider__wrapper {
    display: inline-flex;
    height: 100%; }
  .about-main-slider__slide {
    width: 100%;
    height: 100%;
    background: url("/images/about-us-bg.jpg") no-repeat center;
    background-size: cover; }
  .about-main-slider__prev {
    position: absolute;
    left: 30px;
    top: 0;
    bottom: 0;
    margin: auto 0;
    width: 24px;
    height: 24px;
    transform: rotateY(180deg);
    background: url("/images/icons/arrow-right.svg") no-repeat center;
    background-size: contain;
    cursor: pointer;
    z-index: 3; }
  .about-main-slider__next {
    position: absolute;
    right: 30px;
    top: 0;
    bottom: 0;
    margin: auto 0;
    width: 24px;
    height: 24px;
    background: url("/images/icons/arrow-right.svg") no-repeat center;
    background-size: contain;
    cursor: pointer;
    z-index: 3; }

.about-aside {
  max-width: 295px;
  width: 100%;
  display: flex;
  flex-direction: column; }
  @media screen and (max-width: 1156px) {
    .about-aside {
      display: none; } }
  .about-aside__info {
    display: flex;
    flex-direction: column;
    margin-bottom: 60px; }
  .about-aside__info-title {
    font-size: 24px;
    line-height: 1.25;
    color: #4e4e4e;
    margin-bottom: 30px; }
  .about-aside__info-link {
    font-size: 16px;
    color: #01b9db;
    margin-bottom: 15px; }
    .about-aside__info-link:last-child {
      margin-bottom: 0; }
  .about-aside__info-card {
    width: 100%;
    padding: 25px;
    box-sizing: border-box;
    background-color: #01b9db;
    color: #fff;
    margin-bottom: 20px; }
  .about-aside__links {
    display: flex;
    flex-direction: column; }
  .about-aside__link {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    text-decoration: none; }
    .about-aside__link:last-child {
      margin-bottom: 0px; }
  .about-aside__link-image {
    width: 95px;
    height: 95px;
    background: url("/images/sprites/tor.png") no-repeat center;
    background-size: contain;
    margin-right: 50px;
    flex-shrink: 0; }
  .about-aside__link-title {
    font-size: 17px;
    color: #4e4e4e; }
  .about-aside__title {
    color: #4e4e4e;
    font-size: 25px;
    margin-bottom: 20px; }
  .about-aside__input {
    width: 100%;
    padding: 15px 30px;
    box-sizing: border-box;
    font-size: 16px;
    border-radius: 4px;
    border: solid 1px #9d9d9d;
    margin-bottom: 20px; }
    .about-aside__input:last-child {
      margin-bottom: 0; }
  .about-aside__row {
    display: flex;
    margin-bottom: 24px; }
  .about-aside__checkbox {
    margin-right: 8px; }
  .about-aside__agree {
    font-size: 12px;
    color: #959595; }
  .about-aside__agree-link {
    color: #01a1cf;
    font-size: 12px; }
  .about-aside__input {
    width: 100%;
    padding: 15px;
    box-sizing: border-box;
    font-size: 16px;
    border-radius: 4px;
    border: solid 1px #9d9d9d;
    margin-bottom: 20px;
    color: #959595; }
    .about-aside__input:last-child {
      margin-bottom: 0; }
  .about-aside__textarea {
    width: 100%;
    height: 160px;
    padding: 15px;
    box-sizing: border-box;
    font-size: 16px;
    border-radius: 4px;
    border: solid 1px #9d9d9d;
    color: #959595;
    margin-bottom: 20px;
    resize: none; }
  .about-aside__button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 5px;
    color: #fff;
    box-sizing: border-box;
    max-width: 210px;
    width: 100%;
    border-radius: 4px;
    background-color: #01a1cf;
    cursor: pointer; }

.about-main-item {
  display: flex;
  margin-bottom: 60px; }
  .about-main-item:last-child {
    margin: 0; }
  .about-main-item__image {
    width: 95px;
    height: 95px;
    background: url("/images/sprites/pages.png") no-repeat center;
    background-size: contain;
    margin-right: 50px;
    flex-shrink: 0; }
  .about-main-item__wrapper {
    display: flex;
    flex-direction: column; }
  .about-main-item__date, .about-main-item__vacancy, .about-main-item__description {
    font-size: 16px;
    color: #3f3e33;
    margin-bottom: 30px; }
    .about-main-item__date:last-child, .about-main-item__vacancy:last-child, .about-main-item__description:last-child {
      margin: 0; }

.contacts {
  display: flex;
  flex-direction: column; }
  .contacts__map {
    width: 100%;
    height: 430px;
    margin-bottom: 120px; }
  .contacts__wrapper {
    display: flex;
    flex-direction: column; }
  .contacts__item {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px; }
    .contacts__item:last-child {
      margin: 0; }
  .contacts__text {
    font-size: 17px;
    color: #3f3e33; }
  .contacts__text-title {
    font-size: 17px;
    color: #000; }

.park-map {
  display: flex;
  flex-direction: column; }
  .park-map__google-map {
    width: 100%;
    height: 430px;
    margin-bottom: 120px; }
  .park-map__wrapper {
    display: flex;
    justify-content: space-between; }
  .park-map__column {
    display: flex;
    flex-direction: column;
    max-width: 240px;
    margin-right: 13px;
    width: 100%; }
    .park-map__column:first-child {
      max-width: 305px;
      flex-shrink: 0; }
    .park-map__column:last-child {
      margin-right: 0; }
  .park-map__local-map {
    width: 100%;
    height: 470px;
    background: url("/images/local-map.jpg") no-repeat center;
    background-size: cover;
    margin-bottom: 24.5px; }
  .park-map__link {
    color: #01b9db;
    font-size: 18px;
    text-decoration: none; }
  .park-map__link-desc {
    color: #3f3e33;
    font-size: 18px; }
  .park-map__title {
    font-size: 24px;
    color: #3f3e33;
    margin-bottom: 28px; }
  .park-map__description {
    font-size: 17px;
    color: #3f3e33;
    margin-bottom: 15px; }
  .park-map__list {
    padding: 0; }
  .park-map__list-item {
    margin: 0 0 15px 20px;
    font-size: 17px;
    color: #3f3e33; }

.request {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center; }
  .request__wrapper {
    max-width: 1120px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 70px 0;
    box-sizing: border-box; }
  .request__title {
    font-size: 36px;
    color: #3f3e33;
    font-weight: normal;
    margin: 0 0 24px 0; }
  .request__text {
    font-size: 24px;
    color: #3f3e33;
    margin-bottom: 66px; }
  .request .required:after {
    content: '\274B';
    color: red; }

.request-form__wrapper {
  max-width: 680px; }

.request-form__section {
  margin-bottom: 96px;
  display: flex;
  flex-direction: column; }
  .request-form__section_mb30 {
    margin-bottom: 30px; }

.request-form__row {
  margin-bottom: 20px; }
  .request-form__row_mb30 {
    margin-bottom: 30px; }

.request-form__paragraph {
  padding: 0;
  margin: 0;
  color: #3f3e33;
  font-size: 16px;
  margin-bottom: 23px; }
  .request-form__paragraph_ml8 {
    margin-left: 8px; }

.request-form__input-wrapper {
  display: flex;
  align-items: baseline; }
  .request-form__input-wrapper input[type="text"]:required :after {
    display: block;
    content: '*';
    position: absolute; }
  .request-form__input-wrapper span {
    padding-right: 15px; }
  .request-form__input-wrapper__container {
    width: fit-content; }

.request-form__input {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #BFBFBF; }
  .request-form__input_borders {
    margin-right: 12px;
    padding: 11px 22px 11px 22px;
    box-sizing: border-box;
    border: 1px solid #BFBFBF;
    border-radius: 3px;
    color: #8b8989;
    width: 100%; }
  .request-form__input_100px {
    max-width: 130px; }
  .request-form__input_200px {
    max-width: 200px; }
  .request-form__input_check {
    width: auto; }

.request-form__textarea {
  resize: none;
  min-height: 214px;
  width: 100%;
  padding: 10px;
  box-sizing: border-box; }

.request-form__list {
  color: #3f3e33; }

.request-form__list-item {
  margin-bottom: 17px; }

.request-form__label {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  color: #3f3e33; }

.request-form__checkbox {
  margin-right: 20px; }

.request-form__submit {
  padding: 15px 52px;
  box-sizing: border-box;
  border: none;
  border-radius: 20px;
  background-color: #01A1CF;
  color: #FFF;
  align-self: flex-end;
  cursor: pointer; }
  .request-form__submit_align-start {
    align-self: flex-start; }
  .request-form__submit_icons {
    min-width: 214px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1.6; }

.request-form__submit[disabled=disabled] {
  opacity: .3;
  cursor: auto; }

.request-form-table {
  display: flex;
  flex-direction: column; }
  .request-form-table__head {
    display: flex;
    min-height: 50px;
    border-top: 1px solid #BFBFBF;
    border-right: 1px solid #BFBFBF; }
  .request-form-table__body {
    display: flex;
    flex-direction: column;
    border-right: 1px solid #BFBFBF; }
  .request-form-table__row {
    display: flex;
    width: 100%; }
  .request-form-table__cell {
    height: auto;
    width: 100%;
    padding: 16px 8px 15px 8px;
    box-sizing: border-box;
    display: flex;
    border-bottom: 1px solid #BFBFBF;
    border-left: 1px solid #BFBFBF;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #3f3e33; }
  .request-form-table__input {
    border: none;
    width: 100%; }
  .request-form-table__add {
    margin-top: 15px;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    align-self: flex-end;
    background-color: #BFBFBF;
    border: none;
    position: relative;
    cursor: pointer; }
  .request-form-table__svg {
    display: block;
    position: absolute;
    top: calc(50% - 8px);
    left: calc(50% - 8px); }

#noty_layout__request-success {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: stretch;
  width: 90%;
  height: 40%;
  left: 5%;
  top: 30%;
  z-index: 999999;
  margin: 0 auto; }
  #noty_layout__request-success .noty_bar {
    display: flex;
    justify-content: center;
    align-items: center; }
  #noty_layout__request-success .noty_body {
    padding: 50px 100px;
    text-align: center;
    font-size: 25px;
    line-height: 40px; }

.staff {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .staff__bottom {
    display: flex;
    position: relative; }
    .staff__bottom:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: -55px;
      margin: 0 auto;
      width: calc(100% - 140px);
      height: 1px;
      background-color: #ccc; }
    .staff__bottom:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: -90px;
      margin: 0 auto;
      width: 1px;
      height: 35px;
      background-color: #ccc; }

.staff-head {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 170px; }
  .staff-head__image {
    width: 220px;
    height: 300px;
    background: url("/images/staff/staff-head.jpg") no-repeat center;
    background-size: cover;
    margin-bottom: 17px; }
  .staff-head__name {
    font-size: 20px;
    color: #3f3e33;
    margin-bottom: 16px; }
  .staff-head__position {
    color: #01b9db; }

.staff-bottom-item {
  margin-right: 19px;
  position: relative; }
  .staff-bottom-item:last-child {
    margin: 0; }
  .staff-bottom-item:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: -55px;
    margin: 0 auto;
    width: 1.1px;
    height: 35px;
    background-color: #ccc; }
  .staff-bottom-item__image {
    width: 140px;
    height: 192px;
    background: url("/images/staff/staff-head.jpg") no-repeat center;
    background-size: cover;
    margin-bottom: 17px; }
  .staff-bottom-item__name {
    font-size: 16px;
    color: #3f3e33;
    margin-bottom: 12px; }
  .staff-bottom-item__position {
    color: #01b9db;
    margin-bottom: 20px; }
  .staff-bottom-item__description {
    font-size: 13px;
    color: #3f3e33; }

.review {
  display: flex;
  flex-direction: column; }

.review-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 70px; }
  .review-item:last-child {
    margin-bottom: 0; }
  .review-item__question {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    border-bottom: solid 1px #ededed;
    margin-bottom: 70px;
    position: relative; }
    .review-item__question:after {
      content: '';
      position: absolute;
      width: 20px;
      height: 20px;
      border: 1px solid #ededed;
      border-width: 1px 0 0 1px;
      bottom: -11px;
      right: 210px;
      transform: rotateZ(-135deg);
      background-color: #FFF; }
  .review-item__answer {
    display: flex;
    flex-direction: column;
    position: relative;
    max-width: 80%;
    width: 100%;
    align-self: flex-end;
    box-sizing: border-box;
    border-bottom: solid 1px #01a1cf; }
    .review-item__answer:after {
      content: '';
      position: absolute;
      width: 20px;
      height: 20px;
      border: 1px solid #01a1cf;
      border-width: 1px 0 0 1px;
      bottom: -11px;
      left: 140px;
      transform: rotateZ(-135deg);
      background-color: #FFF; }
    .review-item__answer .review-item__text {
      border-right: solid 1px #01a1cf; }
  .review-item__date {
    color: #898989;
    margin-bottom: 23px; }
  .review-item__name {
    color: #3f3e33;
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 23px; }
  .review-item__text {
    color: #3f3e33;
    border-right: solid 1px #ededed;
    padding: 0 20px 40px 0; }
  .review-item__position {
    color: #01a1cf;
    margin-bottom: 24px;
    font-weight: bold; }

.news-item {
  display: flex;
  margin-bottom: 60px; }
  .news-item:last-child {
    margin: 0; }
  .news-item__image {
    width: 100px;
    height: 100px;
    background: url("/images/news-item2.jpg") no-repeat center;
    background-size: contain;
    margin-right: 50px;
    flex-shrink: 0; }
  .news-item__wrapper {
    display: flex;
    flex-direction: column; }
  .news-item__date {
    font-size: 14px;
    color: #767676;
    margin-bottom: 10px; }
  .news-item__title {
    font-size: 18px;
    color: #4e4e4e;
    margin-bottom: 10px; }
  .news-item__link {
    font-size: 14px;
    color: #01b9db; }

.sitemap-menu__item {
  list-style: none; }

.sitemap-menu__link {
  display: flex;
  align-items: center;
  color: #3f3e33;
  text-decoration: none;
  margin-bottom: 5px; }
  .sitemap-menu__link_level_0 {
    font-weight: bold;
    font-size: 16px; }
  .sitemap-menu__link_level_1:before {
    content: '';
    display: flex;
    align-items: center;
    width: 10px;
    height: 2px;
    background-color: #01b9db;
    margin-right: 5px; }
  .sitemap-menu__link_level_2 {
    font-style: italic; }

.tour {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px; }
  .tour:last-child {
    margin-bottom: 0; }
  .tour__title {
    color: #222;
    font-size: 28px;
    margin-bottom: 35px; }

.tour-item {
  display: flex;
  margin-bottom: 60px;
  text-decoration: none; }
  .tour-item:last-child {
    margin: 0; }
  .tour-item__image {
    width: 100px;
    height: 100px;
    background: url("/images/news-item2.jpg") no-repeat center;
    background-size: contain;
    margin-right: 50px;
    flex-shrink: 0;
    border-radius: 4px; }
  .tour-item__wrapper {
    display: flex;
    flex-direction: column; }
  .tour-item__title {
    font-size: 18px;
    color: #3f3e33;
    text-transform: uppercase;
    text-decoration: underline;
    margin-bottom: 20px; }
  .tour-item__decription {
    color: #3f3e33;
    font-size: 14px;
    margin-bottom: 20px; }
  .tour-item__cost {
    color: #3f3e33;
    font-size: 14px; }
  .tour-item__cost-text {
    color: #3f3e33;
    font-size: 14px;
    font-weight: bold; }
