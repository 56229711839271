.logo {

    max-width: 86px;
    width: 100%;
    height: 50px;
    margin-right: 70px;
    background: url('/images/logo.png') no-repeat center;
    background-size: contain;
    z-index: 12;

    @media screen and (max-width: 976px) {

        display: none;

    }

    &_header {

        display: none;

        @media screen and (max-width: 976px) {

            display: block;
            margin-right: 0;

        }
    }
}
