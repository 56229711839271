.request {

    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__wrapper {

        max-width: 1120px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 70px 0;
        box-sizing: border-box;

    }

    &__title {
    
        font-size: 36px;
        color: $color-text; 
        font-weight: normal;
        margin: 0 0 24px 0;
    
    }

    &__text {
    
        font-size: 24px;
        color: $color-text;
        margin-bottom: 66px;
    
    }
    
    .required {
        &:after {
            content: '\274B';
            color: red;
        }
    }
}

.request-form {

    &__wrapper {
    
        max-width: 680px;
    
    }

    &__section {
    
        margin-bottom: 96px;
        display: flex;
        flex-direction: column;

        &_mb30 {
        
            margin-bottom: 30px;
        
        }
    
    }

    &__row {
    
        margin-bottom: 20px;

        &_mb30 {
        
            margin-bottom: 30px;
        
        }
    
    }

    &__paragraph {
    
        padding: 0;
        margin: 0;
        color: $color-text;
        font-size: 16px;
        margin-bottom: 23px;

        &_ml8 {
        
            margin-left: 8px;
        
        }
    
    }

    &__input-wrapper {
    
        display: flex;
        align-items: baseline;
        
        input[type="text"] {
            
            &:required{
                & :after {
                    display: block;
                    content: '*';
                    position: absolute;
                }
            }

        }

        span {
            
            padding-right: 15px;

        }

        &__container {
            
            width: fit-content;

        }

    }

    &__input {
    
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 1px solid #BFBFBF; 

        &_borders {
        
            margin-right: 12px;
            padding: 11px 22px 11px 22px;
            box-sizing: border-box;
            border: 1px solid #BFBFBF;
            border-radius: 3px;
            color: #8b8989;
            width: 100%;
        
        }

        &_100px {
        
            max-width: 130px;
        
        }

        &_200px {
            max-width: 200px;
        }
        
        &_check {
            
            width: auto;

        }
        
    }

    &__textarea {
    
        resize: none;
        min-height: 214px;
        width: 100%;
        padding: 10px;
        box-sizing: border-box;

    }

    &__list {
    
        color: $color-text;
    
    }

    &__list-item {
    
        margin-bottom: 17px;
    
    }

    &__label {
    
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        color: $color-text;
    
    }

    &__checkbox {
    
        margin-right: 20px;
    
    }

    &__submit {
    
        padding: 15px 52px;
        box-sizing: border-box;
        border: none;
        border-radius: 20px;
        background-color: #01A1CF;
        color: #FFF;
        align-self: flex-end;
        cursor: pointer;

        &_align-start {
        
            align-self: flex-start;
        
        }

        &_icons {
            min-width: 214px;
            display: flex;
            align-items: center;
            justify-content: center;
            line-height: 1.6;
        }
    
    }

    &__submit[disabled=disabled] {
    
        opacity: .3;
        cursor: auto;
    
    }

}

.request-form-table {

    display: flex;
    flex-direction: column;

    &__head { 

        display: flex;
        min-height: 50px;
        border-top: 1px solid #BFBFBF;
        border-right: 1px solid #BFBFBF;

    }

    &__body {
    
        display: flex;
        flex-direction: column;
        border-right: 1px solid #BFBFBF;
    
    }

    &__row {
    
        display: flex;
        width: 100%;
    
    }

    &__cell {

        height: auto;
        width: 100%;
        padding: 16px 8px 15px 8px;
        box-sizing: border-box;
        display: flex;
        border-bottom: 1px solid #BFBFBF;
        border-left: 1px solid #BFBFBF;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        color: #3f3e33;

    }

    &__input {
    
        border: none; 
        width: 100%;
    
    }

    &__add {

        margin-top: 15px;

        width: 40px;
        height: 40px;
        border-radius: 100%;
        align-self: flex-end;
        background-color: #BFBFBF;
        border: none;
        position: relative;
        cursor: pointer;

    }

    &__svg {

        display: block;
        position: absolute;
        top: calc(50% - 8px);
        left: calc(50% - 8px); 

    }

}

#noty_layout__request-success{
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: stretch;
    width: 90%;
    height: 40%;
    left: 5%;
    top: 30%;
    z-index: 999999;
    margin: 0 auto;

    .noty_bar {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .noty_body {

        padding: 50px 100px;
        text-align: center;
        font-size: 25px;
        line-height: 40px;
    }
}