.tour {

    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    &:last-child {
    
        margin-bottom: 0;
    
    }

    &__title {
    
        color: #222;
        font-size: 28px;
        margin-bottom: 35px;
    
    }

}

.tour-item {

    display: flex;
    margin-bottom: 60px;
    text-decoration: none;

    &:last-child {

        margin: 0;

    }

    &__image {

        width: 100px;
        height: 100px;
        background: url('/images/news-item2.jpg') no-repeat center;
        background-size: contain;
        margin-right: 50px;
        flex-shrink: 0;
        border-radius: 4px;

    }

    &__wrapper {

        display: flex;
        flex-direction: column;

    }

    &__title {

        font-size: 18px;
        color: #3f3e33;
        text-transform: uppercase;
        text-decoration: underline;
        margin-bottom: 20px;

    }

    &__decription {
    
       color: #3f3e33;
       font-size: 14px;
       margin-bottom: 20px;
    
    }

    &__cost {

        color: #3f3e33;
        font-size: 14px;


    }

    &__cost-text {
    
     color: #3f3e33;
       font-size: 14px;
       font-weight: bold;
    
    
    }
}
