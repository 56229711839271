.news-item {

    display: flex;
    margin-bottom: 60px;

    &:last-child {

        margin: 0;

    }

    &__image {

        width: 100px;
        height: 100px;
        background: url('/images/news-item2.jpg') no-repeat center;
        background-size: contain;
        margin-right: 50px;
        flex-shrink: 0;

    }

    &__wrapper {

        display: flex;
        flex-direction: column;

    }

    &__date {

        font-size: 14px;
        color: $color-gray;
        margin-bottom: 10px;

    }

    &__title {

        font-size: 18px;
        color: #4e4e4e;
        margin-bottom: 10px;

    }

    &__link {

        font-size: 14px;
        color: $color-cyan;

    }
}
