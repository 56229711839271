.footer {

    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 3;
    position: absolute;
    bottom: 0;

    &_inner-page {

        position: static;
        bottom: auto;
        border-top: 116px solid transparent;
        border-image: url('/images/footer-border.png') 100% 0% 0% / 1 0 0 round;

        .footer__logo {

            background-image: url('/images/footer__logo_cyan.png');
        }


        .footer__logo-unesco {
            
            background-image: url('/images/logo-unesko-cyan.png');

        }
        
        .footer__logo-koygorodskiy {
            
            background-image: url('/images/logo-koygorodskiy-cyan.png');

        }

        .footer__copyrite {

            color: #535353;    

            a {
                text-decoration: underline;
                color: #535353;

                &:hover {
                    text-decoration: none;
                }
            }

        }

        .footer__link {

            color: #535353;

        }

        .footer-social-networks {

            &__link  {

                border-color: $color-cyan;
            }

            &__title {

                color: #535353;

            }

            &__icon {

                &_facebook {

                    background-image: url('/images/icons/facebook-cyan.svg');

                }

                &_classmates {

                    background-image: url('/images/icons/classmates-cyan.svg');

                }

                &_instagram {

                    background-image: url('/images/icons/instagram-cyan.svg');
                }

                &_vk {

                    background-image: url('/images/icons/vk-cyan.svg');

                }
            }
        }
    }

    &__wrapper {

        display: flex;
        justify-content: space-between;
        align-items: center; 
        max-width: 1820px;
        width: 100%;
        padding: 50px;
        flex-direction: row-reverse;

        @media screen and (max-width: 976px) {

            padding: 20px 36px;

        }
    }

    &__copyrite {

        color: $color-white;
        font-size: 14px;
        max-width: 173px;
        margin-right: 70px;

        @media screen and (max-width: 976px) {

            display: none;

        }

        a {
            text-decoration: underline;
            color: $color-white;
        
            &:hover {
                text-decoration: none;
            }
        }



    }

    &__logo {

        max-width: 121px;
        height: 50px;
        width: 100%;
        background: url('/images/footer__logo.png') no-repeat center;
        background-size: contain;

        @media screen and (max-width: 976px) {

            display: none;

        }

        &-unesco {

            @extend .footer__logo;
            background-image: url('/images/logo-unesko.png');

        }

        &-koygorodskiy {

            @extend .footer__logo;
            background-image: url('/images/logo-koygorodskiy.png');

        }


    }

    &__link {

        color: $color-white;

        @media screen and (max-width: 976px) {

            display: none;

        }
    }
}

.footer-social-networks {
    display: flex;
    flex-direction: column;

    &__title {
        color: $color-white;
        margin-bottom: 20px;

        @media screen and (max-width: 976px) {

            display: none;

        }


    }

    &__wrapper{
        display: flex;
        align-items: center;
    }

    &__link{

        margin-right: 20px;
        border: 3px solid $color-white;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 6px;
        box-sizing: border-box;
        max-width: 31px;
        max-height: 31px;
        width: 100%;
        height: 100%;

        &_instagram {

            border: none;
            padding: 0;

        }

        &_inner-page {

            border-color: $color-cyan;

        }

        &:last-child {

            margin-right: 0;

        }

    }

    &__icon {

        &_facebook {
            width: 18px;
            height: 15px;
            background: url('/images/icons/facebook.svg') no-repeat center;
            background-size: contain;

            &_inner-page {

                background-image: url('/images/icons/facebook-cyan.svg');

            }
        }

        &_classmates {

            width: 10px;
            height: 15px;
            background: url('/images/icons/classmates.svg') no-repeat center;
            background-size: contain;

            &_inner-page {

                background-image: url('/images/icons/classmates-cyan.svg');

            }
        }

        &_instagram {

            width: 31px;
            height: 31px;
            background: url('/images/icons/instagram.svg') no-repeat center;
            background-size: contain;

            &_inner-page {

                background-image: url('/images/icons/instagram-cyan.svg');

            }
        }

        &_vk {

            width: 13px;
            height: 15px;
            background: url('/images/icons/vk.svg') no-repeat center;
            background-size: contain;

            &_inner-page {

                background-image: url('/images/icons/vk-cyan.svg');

            }
        }
    }
}
