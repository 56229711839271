.review {

    display: flex;
    flex-direction: column;

}

.review-item {

    display: flex;
    flex-direction: column;
    margin-bottom: 70px;

    &:last-child {

        margin-bottom: 0;

    }

    &__question {

        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        border-bottom: solid 1px #ededed;
        margin-bottom: 70px;
        position: relative;

        &:after {

            content: '';
            position: absolute;
            width: 20px;
            height: 20px;
            border: 1px solid #ededed;
            border-width: 1px 0 0 1px;
            bottom: -11px;
            right: 210px;
            transform: rotateZ(-135deg);
            background-color: #FFF;

        }

    }

    &__answer {

        display: flex;
        flex-direction: column;
        position: relative;
        max-width: 80%;
        width: 100%;
        align-self: flex-end;
        box-sizing: border-box;
        border-bottom: solid 1px #01a1cf;

        &:after {

            content: '';
            position: absolute;
            width: 20px;
            height: 20px;
            border: 1px solid #01a1cf;
            border-width: 1px 0 0 1px;
            bottom: -11px;
            left: 140px;
            transform: rotateZ(-135deg);
            background-color: #FFF;

        }

        .review-item__text {

            border-right: solid 1px #01a1cf;


        }
    }

    &__date {

        color: #898989;
        margin-bottom: 23px;

    }

    &__name {

        color: $color-text;
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 23px;

    }

    &__text {

        color: $color-text;
        border-right: solid 1px #ededed;
        padding: 0 20px 40px 0;

    }

    &__position {

        color: #01a1cf;
        margin-bottom: 24px;
        font-weight: bold;

    } 
}
