.park-map {
    
    display: flex;
    flex-direction: column;

    &__google-map {
    
        width: 100%;
        height: 430px;
        margin-bottom: 120px;
    
    }

    &__wrapper {
    
        display: flex;
        justify-content: space-between;
    
    }

    &__column {
    
        display: flex;
        flex-direction: column;
        max-width: 240px;
        margin-right: 13px;
        width: 100%;

        &:first-child {

            max-width: 305px;
            flex-shrink: 0;
        
        }

        &:last-child {
        
            margin-right: 0;
        
        }
    
    }

    &__local-map {
    
        width: 100%;
        height: 470px;
        background: url('/images/local-map.jpg') no-repeat center;
        background-size: cover;
        margin-bottom: 24.5px;
    
    }

    &__link {
    
        color: $color-cyan;
        font-size: 18px;
        text-decoration: none;
    
    }

    &__link-desc {
    
        color: $color-text;
        font-size: 18px;
    
    }

    &__title {
    
        font-size: 24px;
        color: $color-text;
        margin-bottom: 28px;
    
    }

    &__description {
    
        font-size: 17px;
        color: $color-text;
        margin-bottom: 15px;
        
    }

    &__list {
    
        padding: 0;
    
    }

    &__list-item {
    
        margin: 0 0 15px 20px;
        font-size: 17px;
        color: $color-text;
    
    }
}
