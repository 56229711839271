html {

    height: 100%;

}

.body {
    
    margin: 0;
    padding: 0;
    position: relative !important;
    height: 100%;
    top: 0 !important;

    
    font-family: $font-default;
    
}

.main {

    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__wrapper {

        width: 100%;
        height: 100%;

    }

    &__row {
    
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;
    
    }

}

.p-text {

    font-size: 14px;
    color: #707070;
    line-height: 1.71;
    margin: 0 0 25px;

}
