.header {

    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    overflow: hidden;

    &_inner-page {

        background: url('/images/bg-about.jpg') no-repeat center; 
        background-size: cover;

        .header__wrapper {

            flex-direction: column;
            padding: 50px 50px 130px 50px;

        }
    }

    &_cyan {

        background: none;

        .header__wrapper {

            padding: 50px 50px 20px 50px;

        }

        .menu__link, .header-links__text, .header-language-selection__language, .header-main__title {

            color: $color-text;

        }

        .hamburger-inner {

            background-color: $color-gray !important;

            &:after, &:before {

                background-color: $color-gray !important;

            }
        }

        .header-toolbar__search {

            background-image: url('/images/icons/search_inner-page.svg');

        }

        .logo {

            background-image: url('/images/logo_cyan.png');

        }

    }


    &__wrapper {

        display: flex;
        justify-content: space-between;
        align-items: center; 
        max-width: 1920px;
        width: 100%;
        padding: 50px 50px;
        z-index: 3;

        @media screen and (max-height: 786px) {

            padding: 20px 50px;

        }

    }

    &__top-line {

        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 120px;

    }

    &__video-bg {
    
        position: absolute;
        top: 0;
        left: 0;
        min-width: 100%;
        min-height: 100%;
        height: auto;
    
    }
}

.header-main {

    max-width: 1120px;
    width: 100%;

    &__links {

        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 38px;

    }

    &__link {

        padding: 6px 7px;
        box-sizing: border-box;
        background-color: #01a1cf;
        text-decoration: underline;
        color: #FFF;
        font-size: 15px;
        border-radius: 4px;
        text-align: left;
        margin-bottom: 15px;
        margin-right: 4px;

        &_active {

            background-color: #FFF;
            color: #01a1cf;
            border: 1px solid #01a1cf;

        }

        &:last-chiild {
        
            margin-right: 0;
        
        }
    }

    &__title {

        font-size: 60px;
        color: #FFF;
        letter-spacing: 0.1px;
        margin-bottom: 27px;
        font-stretch: ultra-condensed;

        @media screen and (max-width: 1156px) {

            font-size: 32px;

        }
    }

    &__text {

        font-size: 18px;
        color: #FFF;

    }

    &__nav {

        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;

    }

    &__nav-link {

        max-width: 200px;
        padding: 6px 7px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        box-sizing: border-box;
        border: 1px solid #01a1cf;
        border-radius: 4px;
        color: $color-cyan;
        font-size: 18px;
        margin-right: 15px;
        margin-bottom: 15px;
        transition: .6s ease;

        &:hover {
        
            transition: 1.5s ease;
            white-space: normal;
        
        }

        &_active {

            font-weight: bold; 
            text-decoration: none;

        }

        &:last-child {

            margin-right: 0;

        }
    }
}


.header-toolbar {

    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 50px;
    z-index: 12;

    @media screen and (max-width: 976px) {
    
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin-right: 0;
    
    }

    &__menu-btn {

        padding: 0;
        cursor: pointer;

        .hamburger-box {

            width: 20px;

        }

        .hamburger-inner,.hamburger-inner:before,.hamburger-inner:after {

            width: 100%;
            background-color: #fff;
            height: 2px;

        }

        .hamburger-inner:before {

            top: -6px;

        }

        .hamburger-inner:after {

            bottom: -6px;

        }
    }

    &__search {

        width: 28px;
        height: 16px;
        background: url('/images/icons/search.svg') no-repeat center;
        background-size: contain;
        cursor: pointer;
        margin-bottom: 7px;

    }

    &__accessibility {

        width: 30px;
        height: 21px;
        background: url('/images/icons/accessibility.svg') no-repeat center;
        background-size: contain;
        cursor: pointer;

        @media screen and (max-width: 976px) {

            display: none;

        }
    }
}

.header-links {

    display: flex;
    align-items:  center;
    flex-grow: 1;
    margin-right: 25px;
    z-index: 3;

    @media screen and (max-width: 976px) {

        display: none;

    }

    &__link {

        display: flex;
        align-items: center;
        flex-shrink: 0;
        flex-grow: 1;
        z-index: 2;
        
        &:hover {
        
            text-decoration: none;

        }

        &_image {

            max-width: 214px;
            width: 100%;
            height: 69px;
            background: url('/images/header-links__image2.png') no-repeat center;
            background-size: contain;

        }
    }

    &__image {

        max-width: 57px;
        width: 100%;
        height: 50px;
        background: url('/images/header-links__image.png') no-repeat center;
        background-size: contain;
        margin-right: 25px;

    }

    &__text {

        color: $color-white;
        max-width: 200px;
        width: 100%;
        font: 12px medium $font-default;
        text-decoration: underline;

        &:hover {
        
            text-decoration: none;
        
        }

    }
}

.header-language-selection {

    display: flex;
    flex-direction: column;
    z-index: 3;

    @media screen and (max-width: 976px) {

        display: none;

    }

    &__language {

        color: $color-white;
        background-color: transparent;
        border: none;

        &:first-child {

            margin-bottom: 12px;

        }
    }
}

