.staff {

    display: flex;
    flex-direction: column;
    align-items: center;

    &__bottom {

        display: flex;
        position: relative;

        &:before {

            content: '';
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            top: -55px;
            margin: 0 auto;
            width: calc(100% - 140px);
            height: 1px;
            background-color: #ccc;

        }

        &:after {

            content: '';
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            top: -90px;
            margin: 0 auto;
            width: 1px;
            height: 35px;
            background-color: #ccc;

        }
    }
}

.staff-head {

    // display: grid;
    // grid-template-columns: 1fr;
    // grid-auto-rows: repeat(1fr, 3);
    // grid-gap: 0 20px;

    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 170px;

    &__image {

        width: 220px;
        height: 300px;
        background: url('/images/staff/staff-head.jpg') no-repeat center;
        background-size: cover;
        margin-bottom: 17px;

    }

    &__name {

        font-size: 20px;
        color: $color-text;
        margin-bottom: 16px;

    }

    &__position {

        color: $color-cyan;

    }
}

.staff-bottom-item {

    margin-right: 19px;
    position: relative;

    &:last-child {

        margin: 0;

    }

        &:before {

        content: '';
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: -55px;
        margin: 0 auto;
        width: 1.1px;
        height: 35px;
        background-color: #ccc;

    }

    &__image {

        width: 140px;
        height: 192px;
        background: url('/images/staff/staff-head.jpg') no-repeat center;
        background-size: cover;
        margin-bottom: 17px;

    }

    &__name {
    
        font-size: 16px;
        color: $color-text;
        margin-bottom: 12px;
    
    }

    &__position {

        color: $color-cyan;
        margin-bottom: 20px;

    }

    &__description {

        font-size: 13px;
        color: $color-text;

    }
}
