.popup-wrapper {

    display: flex;
    align-items: center;
    height: 100%;
    padding: 15px;
    box-sizing: border-box;
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    background-color: #fdbf2e !important;
    text-align: center;

}

.feedback-popup {

    display: flex;
    flex-direction: column;
    max-width: 940px; 
    width: 100%;
    height: 540px;
    padding: 110px 130px;
    box-sizing: border-box;
    background: url('/images/popup-bg.jpg');

    @media screen and (max-width: 656px) {
    
        padding: 15px;
    
    }


    &__title {

        font-size: 25px;
        color: #413535;
        margin-bottom: 2px;

    }

    &__description {

        font-size: 18px;
        color: #413535;
        margin-bottom: 33px;

    }

    &__column {

        display: flex;
        flex-direction: column;
        max-width: 340px;
        width: 100%;

    }

    &__input {

        width: 100%;
        padding: 18px 38px;
        box-sizing: border-box;
        margin-bottom: 11px;
        border: solid 1px #707070;
        border-radius: 4px;

    }

    &__row {

        display: flex;
        margin-bottom: 26px;

    }

    &__checkbox {

        margin-right: 8px;
        align-self: flex-start;

    }

    &__text {

        max-width: 280px;

    }

    &__button {

        display: flex;
        justify-content: center;
        align-items: center;
        padding: 15px 5px;
        color: #fff;
        box-sizing: border-box;
        max-width: 210px;
        width: 100%;
        border: solid 1px #707070;
        border-radius: 4px;
        background-color: #01a1cf;
        cursor: pointer;

    }
}
