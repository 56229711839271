.menu {

    display: flex;
    align-items: center;
    padding: 0;
    margin: 0 25px 0 0;
    flex-wrap: wrap;
    z-index: 3;

    @media screen and (max-width: 1636px) {

        max-width: 500px;

    }

    @media screen and (max-width: 1456px) {

        max-width: 340px;

    }

    @media screen and (max-width: 976px) {
    
        display: none;
    
    }

    &__item {

        list-style-type: none;
        margin-right: 25px;

        &:last-child {

            margin-right: 0;

        }

    }

    &__link {

        color: $color-white;
        font-stretch: ultra-condensed;

        &:hover {
        
            text-decoration: none;

        }

        &_active {
        
            text-decoration: none;
        
        }
    }
}
