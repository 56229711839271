.swiper-main {

    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;


    &__wrapper {

        display: inline-flex;
        height: 100%;

    }

    &__slide {

        width: 100%;
        height: 100%;
        background: url('/images/bg-main.jpg') no-repeat center;
        background-size: cover;

    }

    &__pagination {

        position: absolute;
        bottom: 110px;
        right: 750px;
        margiin: 0 auto;
        z-index: 4;

        display: flex;
        height: 24px;

        @media screen and (max-width: 976px) {

            display: none;

        }

        .swiper-pagination-bullet {

            width: 14px;
            height: 14px;
            background-color: #fff;
            border-radius: 50%;
            margin-right: 45px;
            position: relative;

            &:after {

                content: '';
                position: absolute;
                width: 24px;
                height: 24px;
                background-color: transparent;
                border: 1px solid #fff;
                border-radius: 50%;
                top: -6px;
                left: -6px;
                opacity: 0;
                visibility: collapse;
                transition: .6s ease;

            }

            &-active {

                &:after {

                    opacity: 1;
                    visibility: visible;
                    transition: .6s ease;

                }
            }

            &:last-child {

                margin-right: 0;

            }
        }
    }

    &__next,&__prev {

        width: 78px;
        height: 78px;
        background: $color-gray no-repeat center;
        border: 1px solid $color-gray-border;
        border-radius: 50%;
        cursor: pointer;
        top: 45%;
        z-index: 7;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;

        &:after {

            content: '';
            position: absolute;
            background: url('/images/icons/arrow-left.svg') no-repeat center;
            background-size: contain;
            width: 20px;
            height: 20px;

        }

        @media screen and (max-width: 976px) {

            display: none;

        }
    }

    &__prev {

        left: 100px;

    }

    &__next {

        transform: rotateY(180deg);
        right: 100px;

    }
}

.swiper-slide-active {
    z-index: 2;
}

.swiper-main-slide {

    &:after {

        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0,0,0,.5);
        z-index: 2;

    }

    &__wrapper {

        position: relative;
        padding: 275px 650px 275px 215px;
        z-index: 5;
        height: 100%;
        box-sizing: border-box;

        @media screen and (max-height: 786px) {

            padding: 215px 620px 275px 215px;

        }

        @media screen and (max-width: 1236px) {

            padding: 175px 215px 175px 215px;

        }

        @media screen and (max-width: 976px) {

            padding: 125px 85px 125px 85px;

        }
    }

    &__title {

        font-size: 30px;
        color: $color-white;
        margin-bottom: 40px;

        @media screen and (max-width: 1516px) {

            font-size: 24px;

        }

        @media screen and (max-width: 976px) {

            display: none;

        }
    }

    &__title-mobile {

        display: none;

        &-description {

            font-size: 16px;
            margin-bottom: 15px;
            color: #fff;
            letter-spacing: 1.6px;
            text-shadow: 0 3px 6px #000000fd;

        }

        &-main {

            font-size: 48px;
            font-weight: 300;
            color: #fff;
            letter-spacing: 1.6px;
            text-shadow: 0 3px 6px #000000fd;

        }

        @media screen and (max-width: 976px) {

            display: flex;
            flex-direction: column;
            align-items: center;

        }
    }

    &__acent {

        font-size: 72px;
        color: $color-white;
        margin-bottom: 28px;

        @media screen and (max-width: 1516px) {

            font-size: 36px;

        }

        @media screen and (max-width: 976px) {

            display: none;

        }
    }

    &__description {

        font-size: 30px;
        color: $color-white; 
        margin-bottom: 70px;
        line-height: 1.25;
        text-shadow: 0 3px 6px #000000;

        @media screen and (max-width: 1516px) {
            font-size: 20px;
        }

        @media screen and (max-width: 976px) {

            font-size: 16px;

        }
    }

    &__buttons {

        display: flex;

        @media screen and (max-width: 976px) {

            .button_cyan {
            
                display: none;
            
            }
        }
    }
}

.main-news {

    position: absolute;
    right: 0;
    display: flex;
    flex-direction: column;
    max-width: 630px;
    width: 100%;
    z-index: 6;
    padding-right: 190px;
    box-sizing: border-box;

    @media screen and (max-width: 1236px) {

        display: none;

    }

    &__top-section {
        display: flex;
        flex-direction: column;
        margin-bottom: 50px;
        padding-left: 35px;
    }

    &__bottom-section {
        display: flex;
        flex-direction: column;
    }

    &__wrapper {

        display: flex;
        flex-direction: column;
        text-decoration: none;

        &_scroll {

            max-height: 270px;
            height: 100%;
            overflow: auto;
            position: relative;

            &:hover {
                .ps__rail-y {
                    opacity: 1;
                }
            }

            @media screen and (max-height: 846px) {

                max-height: 206px;

            }

            /* SCROLL STYLES */

            .ps__rail-y {
                right: auto;
                left: 0;
                opacity: 0.7;
                background-color: transparent !important;
                cursor: pointer;

                &:before {
                    content: '';
                    position: absolute;
                    height: 100%;
                    width: 2px;
                    background-color: #fff;
                    opacity: 0.8;
                    left: 3px;
                }
            }

            .ps__thumb-y {
                background-color: #FFF;
                width: 8px;
                right: auto;
            }

        }
    }

    &__title {
        color: $color-white;
        margin-bottom: 33px;

        &_big {
            font-size: 30px;
            text-decoration: underline;

            &:hover {

                text-decoration: none;

            }
        }

        &_small {
            font-size: 18px;
            font-weight: 500;
        }
    }
}

.main-news-item {

    display: flex;
    margin-bottom: 25px;
    text-decoration: none;
    flex-shrink: 0;

    &_scroll {

        padding-left: 35px;

    }

    &:last-child {

        margin-bottom: 0;

    }

    &__image {
        max-width: 90px;
        width: 100%;
        height: 90px;
        margin-right: 2px;
        flex-shrink: 0;
        background: url('/images/news-item.jpg') no-repeat center;
        background-size: cover;
        margin-right: 25px;
        border-radius: 10px;
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
    }

    &__title {
        margin-bottom: 12px;
        color: $color-white;
        font-size: 15px;
        text-decoration: underline;

        &:hover {

            text-decoration: none;

        }


    }
    &__description {
        margin-bottom: 18px;
        color: $color-white;
        font-size: 18px;
        text-decoration: underline;

        &:hover{

            text-decoration: none;

        }

    }
    &__date {
        color: $color-white;
        font-size: 14px;
    }
}
