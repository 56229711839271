.button {

    display: flex;
    cursor: pointer;

    &_cyan {

        justify-content: center;
        align-items: center;
        max-width: 230px;
        width: 100%;
        color: $color-white;
        box-sizing: border-box;
        padding: 20px 10px;
        background-color: $color-cyan;
        border: 1px solid $color-cyan;
        border-radius: 6px;
        margin-right: 20px;
        text-align: center;

    }

    &_transition {

        justify-content: center;
        align-items: center;
        max-width: 230px;
        width: 100%;
        color: $color-white;
        box-sizing: border-box;
        padding: 20px 10px;
        text-decoration: underline;
        border: 1px solid $color-white;
        border-radius: 6px;
        text-align: center;

        @media screen and (max-width: 976px) {
        
            max-width: 135px;
            border-radius: 5px;
            background-color: #01a1cf;
            border: none;
            padding: 10px;
            margin: 0 auto;
            text-decoration: none;

        
        }
    }

    &_write-us {
    
        @extend .button_cyan;
        max-width: 300px;
        padding: 15px;
        margin: 0 0 65px 0;

        &:before {

            content: '';
            display: block;
            width: 19px;
            height: 18px;
            background: url('/images/icons/write-us.svg') no-repeat center;
            background-size: contain;
            margin-right: 14px;

        }
    }
}
