.about { 

    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__wrapper {

        max-width: 1120px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 70px 0;
        box-sizing: border-box;

    }

    &__content {

        width: 100%;
        margin-right: 25px;
        @media screen and (max-width: 1156px) {

            margin-right: 0;
            padding: 0 15px;

        }
    }
}

.about-main {


    &__text-title {

        font-size: 25px;
        font-stretch: ultra-condensed;
        line-height: 36px;
        color: $color-text;
        margin-bottom: 30px;

    }

    &__content {

        color: $color-text;
        font-size: 17px;
        line-height: 30px;

    }

}

.about-main-slider {

    max-width: 752px;
    width: 100%;
    margin-bottom: 80px;
    height: 330px;
    overflow: hidden;
    position: relative;

    &__wrapper {

        display: inline-flex;
        height: 100%;

    }

    &__slide {

        width: 100%;
        height: 100%;
        background: url('/images/about-us-bg.jpg') no-repeat center;
        background-size: cover;

    }

    &__prev {

        position: absolute;
        left: 30px;
        top: 0;
        bottom: 0;
        margin: auto 0;
        width: 24px;
        height: 24px;
        transform: rotateY(180deg);
        background: url('/images/icons/arrow-right.svg') no-repeat center;
        background-size: contain;
        cursor: pointer;
        z-index: 3;


    }

    &__next {

        position: absolute;
        right: 30px;
        top: 0;
        bottom: 0;
        margin: auto 0;
        width: 24px;
        height: 24px;
        background: url('/images/icons/arrow-right.svg') no-repeat center;
        background-size: contain;
        cursor: pointer;
        z-index: 3;

    }
}

.about-aside {

    max-width: 295px;
    width: 100%;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 1156px) {
    
        display: none;
    
    }

    &__info {

        display: flex;
        flex-direction: column;
        margin-bottom: 60px;

    }

    &__info-title {

        font-size: 24px;
        line-height: 1.25;
        color: $color-black;
        margin-bottom: 30px;


    }

    &__info-link {

        font-size: 16px;
        color: $color-cyan;
        margin-bottom: 15px;

        &:last-child {

            margin-bottom: 0;

        }

    }

    &__info-card {

        width: 100%;
        padding: 25px;
        box-sizing: border-box;
        background-color: $color-cyan;
        color: #fff;
        margin-bottom: 20px;


    }

    &__links {

        display: flex;
        flex-direction: column;

    }

    &__link {

        display: flex;
        align-items: center;
        margin-bottom: 30px;
        text-decoration: none;

        &:last-child {

            margin-bottom: 0px;

        }
    }

    &__link-image {

        width: 95px;
        height: 95px;
        background: url('/images/sprites/tor.png') no-repeat center;
        background-size: contain;
        margin-right: 50px;
        flex-shrink: 0;

    }

    &__link-title {

        font-size: 17px;
        color: $color-black;

    }

    &__title {

        color: #4e4e4e;
        font-size: 25px;
        margin-bottom: 20px;

    }

    &__input {

        width: 100%;
        padding: 15px 30px;
        box-sizing: border-box;
        font-size: 16px;
        border-radius: 4px;
        border: solid 1px #9d9d9d;
        margin-bottom: 20px;

        &:last-child {

            margin-bottom: 0;

        }

    }

    &__row {

        display: flex;
        margin-bottom: 24px;

    }

    &__checkbox {

        margin-right: 8px;

    }

    &__agree {

        font-size: 12px;
        color: #959595;

    }

    &__agree-link {

        color: #01a1cf;
        font-size: 12px;

    }

    &__input {

        width: 100%;
        padding: 15px;
        box-sizing: border-box;
        font-size: 16px;
        border-radius: 4px;
        border: solid 1px #9d9d9d;
        margin-bottom: 20px;
        color: #959595;

        &:last-child {

            margin-bottom: 0;

        }
    }

    &__textarea {

        width: 100%;
        height: 160px;
        padding: 15px;
        box-sizing: border-box;
        font-size: 16px;
        border-radius: 4px;
        border: solid 1px #9d9d9d;
        color: #959595;
        margin-bottom: 20px;
        resize: none;

    }

    &__button {

        display: flex;
        justify-content: center;
        align-items: center;
        padding: 15px 5px;
        color: #fff;
        box-sizing: border-box;
        max-width: 210px;
        width: 100%;
        border-radius: 4px;
        background-color: #01a1cf;
        cursor: pointer;

    }
}

.about-main-item {

    display: flex;
    margin-bottom: 60px;

    &:last-child {

        margin: 0;

    }

    &__image {

        width: 95px;
        height: 95px;
        background: url('/images/sprites/pages.png') no-repeat center;
        background-size: contain;
        margin-right: 50px;
        flex-shrink: 0;

    }

    &__wrapper {

        display: flex;
        flex-direction: column;

    }

    &__date, &__vacancy, &__description {

        font-size: 16px;
        color: $color-text;
        margin-bottom: 30px;

        &:last-child {

            margin: 0;

        }
    }
}
