.contacts {
    
    display: flex;
    flex-direction: column;

    &__map {
    
        width: 100%;
        height: 430px;
        margin-bottom: 120px;
    
    }

    &__wrapper {
    
        display: flex;
        flex-direction: column;
    
    }

    &__item {
    
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;

        &:last-child {
        
            margin: 0;
        
        }
    }

    &__text {
    
        font-size: 17px;
        color: $color-text;
    
    }

    &__text-title {
   
        font-size: 17px;
        color: #000;
    
    }
}
