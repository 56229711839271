.link {
    
    &__main {
        
        color: white;
        transition: all .3s ease-in-out;
        
        &:hover {
            
            text-decoration: none;
            transition: all .3s ease-in-out;

        }

    }

}
