.pages {
    
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;

}


.left-arrow, .right-arrow {

    text-decoration: none;

}

.left-arrow {
    
    &:before {

        display: block;
        content: "\2039";
        box-sizing: border-box;
    
    }

}

.right-arrow {

    &:after {

        box-sizing: border-box;
        display: block;
        content: "\203A";

    }

}

.page {

    color: black;
    padding: 5px;

}

.active {
    
    text-decoration: none;

}
