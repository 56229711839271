.partners-item {

    display: flex;
    align-items: center;
    margin-bottom: 60px;

    &:last-child {

        margin: 0;

    }

    &__image-wrapper {

        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 220px;
        width: 100%;
        min-height: 220px;
        border: solid 1px #bfbfbf;
        box-sizing: border-box;
        padding: 18px;
        margin-right: 50px;
        flex-shrink: 0;

    }

    display: flex;
    margin-bottom: 60px;

    &:last-child {

        margin: 0;

    }

    &__image {

        max-width: 184px;
        max-height: 180px;
        width: 100px;
        height: 100px;
        background: url('/images/partner.png') no-repeat center;
        background-size: contain;

    }

    &__wrapper {

        display: flex;
        flex-direction: column;

    }

    &__title {

        font-size: 24px;
        color: $color-text;

    }
}

.news-item {

    display: flex;
    margin-bottom: 60px;

    &:last-child {

        margin: 0;

    }

    &__image {

        width: 100px;
        height: 100px;
        background: url('/images/news-item2.jpg') no-repeat center;
        background-size: contain;
        margin-right: 50px;
        flex-shrink: 0;

    }

    &__wrapper {

        display: flex;
        flex-direction: column;

    }

    &__date {

        font-size: 14px;
        color: $color-gray;
        margin-bottom: 10px;

    }

    &__title {

        font-size: 18px;
        color: #4e4e4e;
        margin-bottom: 10px;

    }

    &__link {

        font-size: 14px;
        color: $color-cyan;

    }
}

.documents-item {

    display: flex;
    margin-bottom: 60px;

    &:last-child {

        margin: 0;

    }

    &__image {

        width: 100px;
        height: 100px;
        background: url('/images/sprites/word.png') no-repeat center;
        background-size: contain;
        margin-right: 50px;
        flex-shrink: 0;

    }

    &__wrapper {

        display: flex;
        flex-direction: column;

    }

    &__date {

        font-size: 14px;
        color: $color-gray;
        margin-bottom: 10px;

    }

    &__title {

        font-size: 18px;
        color: #4e4e4e;
        margin-bottom: 10px;

    }

    &__link {

        font-size: 14px;
        color: $color-cyan;


    }
}
